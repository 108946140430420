import React from "react"
import Page from "../layouts/Page";
import Anthony from "../media/team/Anthony.jpeg"
import Jorge from "../media/team/Jorge.jpg"
import { graphql } from "gatsby";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    const author = frontmatter.author;
    return (
        <Page title={frontmatter.title} description={frontmatter.title}>
        <section className="section">
            <div className="container">
                <div class="columns">
                    <div class="column is-7">
                        <div class="content is-medium">
                        <div class="icon-text">
                            <a href="/blog" alt="Retour aux articles">
                            <span class="icon">
                            <i class="fas fa-arrow-left"></i>
                            </span>
                            <span>Autres articles</span>
                            </a>
                            </div>
                            <hr />
                            <div className="blog-post-container">
                                <div class="columns has-text-left is-vcentered">
                                    <div class="column is-3">
                                        <figure class="image" style={{marginLeft: "0em"}}>
                                            <img class="is-rounded" src={author === 'Anthony Dubois' ? Anthony : Jorge} />
                                        </figure>
                                    </div>
                                    <div class="column is-9">
                                        <b>{frontmatter.author}</b><br />{frontmatter.date} - {frontmatter.minutes} min read
                                    </div>
                                </div>
                                <div className="blog-post">
                                    {/* <p><b>{frontmatter.author}</b> - {frontmatter.date} - {frontmatter.minutes} min read</p> */}
                                    <hr />
                                    <h1>{frontmatter.title}</h1>
                                    <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-4 is-centered">
                    </div>
                </div>
            </div>
        </section>
        </Page>
        )
    }
    
    export const pageQuery = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                author
                minutes
            }
        }
    }
    `